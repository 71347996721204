<template>
  <el-drawer
    class="groupSetUp-drawer"
    title="权限设置"
    :visible.sync="drawer"
    :append-to-body="true"
    direction="rtl"
    :before-close="handleClose"
  >
    <div class="drawer-container">
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="选择公司">
            <el-select
              clearable
              class="common-screen-input_100"
              :value="tableRow.companyName"
              disabled
              filterable
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门名称">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入名称"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="部门主管">
            <el-select
              class="common-screen-input_100"
              v-model="ruleForm.leaderIds"
              filterable
              @change="testFun"
              multiple
              placeholder="搜索"
            >
              <el-option
                v-for="item in userList"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="groupSetUp-btn">
        <el-button class="common-screen-btn" @click="handleClose()"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitMenu()"
          >确 定</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { setupDepart } from "../../../service/manage.js";
import { Config } from "../../../utils/index.js";
export default {
  props: ["drawer", "tableRow"],
  data() {
    return {
      ruleForm: {
        name: "",
        leaderIds: []
      },
      menuAll: [],
      userList: [],
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
    };
  },
  methods: {
    testFun(e) {
      console.log(e);
    },
    getTableRow(row) {
      this.ruleForm = Object.assign(this.ruleForm, {
        name: row.name,
        leaderIds: row.leaderIds ? row.leaderIds.split(",") : [],
      });
    //   this.ruleForm.name = row.name
    //   this.ruleForm.leaderIds = row.leaderIds.split(",")
    },
    getUserList(userList) {
      // 获取部门主管下拉
      this.userList = userList;
    },
    async submitMenu() {
      // 提交设置部门
      let tableRowId = this.tableRow.id;
      let ruleForm = { ...this.ruleForm };
      console.log(ruleForm, "--------------------");
      if (typeof ruleForm.leaderIds == "object") {
        ruleForm.leaderIds = ruleForm.leaderIds.join(",");
      }

      await setupDepart(ruleForm, tableRowId);
      this.$message.success("设置成功");
      this.handleClose();
    },
    handleClose() {
      this.$emit("handleClose");
      this.$emit("departList");
    },
  },
};
</script>
<style lang="less" scoped>
.groupSetUp-drawer {
  /deep/.el-drawer__open .el-drawer.rtl {
    width: 38% !important;
  }
  .drawer-container {
    padding: 12px 56px;
    box-sizing: border-box;
  }
  //   .groupSetUp-content {
  //     padding: 12px 56px;
  //   }
  .groupSetUp-btn {
    background: #fff;
    margin: 60px 0 0 160px;
  }
  .groupSetUp-tip {
    margin-left: 24px;
    height: 37px;
    border-bottom: 1px solid #e8e8e8;
    padding-left: 16px;
    color: #000000d9;
  }
  .checkbox-item {
    margin: 12px 0 12px 36px;
  }
}
</style>