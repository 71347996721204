<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="companyList-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- <div v-if="!$vuex.state.onlyCompany" class="common-screen-container">
      <div class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.id"
          placeholder="请选择"
          @change="commonFun"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => departList()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div> -->
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => addEdit()"
      >新增部门</el-button
    >
    <el-table
      :row-class-name="getRowClass"
      class="common-table"
      :data="tableData"
      row-key="id"
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="name"
        :formatter="tableColumn"
        label="部门"
      ></el-table-column>
      <el-table-column
        prop="leaderName"
        :formatter="tableColumn"
        label="负责人"
      ></el-table-column>
      <el-table-column
        prop="number"
        :formatter="tableColumn"
        label="人数"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        :formatter="tableColumn"
        label="所属公司"
      ></el-table-column>
      <el-table-column
        prop="createBy"
        :formatter="tableColumn"
        label="创建人"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        :formatter="tableColumn"
        label="创建时间"
        width="200px"
      ></el-table-column>
      <el-table-column label="操作" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => addEdit(scope.row)"
            >新增部门</el-button
          >
          <el-button type="text" size="small" @click="() => handleClose(true, scope.row)"
            >设置部门</el-button
          >
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="() => deleteItem(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="新增部门"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="部门名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleForm.name"
              maxLength="50"
              placeholder="请输入部门名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属公司" prop="companyId">
            <el-select
              :disabled="leaderId ? true : false"
              class="dialog-input"
              v-model="ruleForm.companyId"
              filterable
              placeholder="请选择所属公司"
              @change="(e) => selectFun(e)"
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门负责人">
            <el-select
              class="dialog-input"
              v-model="ruleForm.leaderIds"
              filterable
              multiple
              placeholder="搜索"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <group-set-up
      ref="groupSetUp"
      :tableRow="tableRow"
      :drawer="drawer"
      :userList="userList"
      @handleClose="handleClose"
      @departList="departList"
    ></group-set-up>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import GroupSetUp from "./children/groupSetUp.vue";
import {
  departList,
  getUserList,
  addDepart,
  deleteDepart,
} from "../../service/manage.js";
import { Config, tableColumn } from "../../utils/index.js";
import { mapState } from "vuex";

export default {
  components: {
    Breadcrumb,
    GroupSetUp,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      drawer: false, // 设置权限弹窗
      centerDialogVisible: false,
      tableRow: "", // 设置部门参数
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "部门设置", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      ruleForm: {
        name: "",
        companyId: "",
        leaderIds: "",
      },
      leaderId: "", // 部门上级id
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "change" }],
        companyId: [{ required: true, message: "请选择部门负责人", trigger: "change" }],
      },
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      userList: [], // 部门负责人下拉
      tableData: [],
    };
  },
  created() {
    this.getComId()
    
    // this.departList();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.id = this.comId
        }else{
          this.params.id = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.departList();
      },400)
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.departList()
    },
    handleClose(open, row) {
      // 打开/关闭设置弹窗
      if (!open) {
        this.drawer = false;
      } else {
        this.drawer = true;
        this.userList = [];
      }
      if (row) {
        this.tableRow = row;
        this.getUserList(row.companyId, "setUserList");
        this.$refs["groupSetUp"].getTableRow(row);
        this.$refs["groupSetUp"].getUserList(this.getUserList);
      }
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        id: this.comId
      };
      this.departList();
    },
    submitForm(formName) {
      // 新增修改提交
      let leaderId = this.leaderId;
      let ruleForm = this.ruleForm;
      if (typeof ruleForm.leaderIds == "object") {
        ruleForm.leaderIds = ruleForm.leaderIds.join(",");
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res
          if (leaderId) {
            res = await addDepart({ ...ruleForm, groupParentId: leaderId });
          } else {
            res = await addDepart(ruleForm);
          }
          if (res.code == 200) {
            this.$message.success("添加成功");
          }
          this.params.pageNum = 1;
          this.params.pageSize = 10;
          this.centerDialogVisible = false;

          this.departList();
        } else {
          return false;
        }
      });
    },
    selectFun(e) {
      // 所属公司改变时
      if (e) {
        this.getUserList(e);
      } else {
        this.userList = [];
      }
      this.ruleForm.leaderIds = "";
    },
    async getUserList(companyId, type) {
      // 部门负责人下拉，暂用员工列表
      let resData = (
        await getUserList({ pageNum: -1, companyId: companyId })
      ).data;
      if (type == "setUserList") {
        this.$refs["groupSetUp"].getUserList(resData);
      }
      this.userList = resData;
    },
    getRowClass(row) {
      // 设置表格样式
      let res = [];
      if (!row.row.children) {
        res.push("row-expand-cover");
      }
      return res.join(" ");
    },
    async departList() {
      // 获取列表
      this.loading = true;
      let params = { ...this.params };
      let resData = (await departList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.departList();
    },
    addEdit(row) {
      if (!row) {
        this.ruleForm = {
          name: "",
          companyId: "",
          leaderIds: "",
        };
        this.leaderId = "";
      } else {
        this.getUserList(row.companyId);
        this.leaderId = row.id;
        this.ruleForm = {
          name: "",
          companyId: row.companyId,
          leaderIds: "",
        };
      }
      if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      this.centerDialogVisible = true;
    },
    async deleteItem(id) {
      await deleteDepart({}, id);
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.$message.success("删除成功");
      this.departList();
    },
  },
};
</script>
<style lang="less" scoped>
.companyList-container {
  text-align: left;
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-table__expanded-cell {
  padding: 0 0 0 78px !important;
}
/deep/ .row-expand-cover .el-table__expand-column .el-icon {
  visibility: hidden;
}
</style>
